.App {
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.background-individual-learning {
  width: 100%;
  height: 250px;
  background-image: url('https://img.freepik.com/free-photo/front-view-concentrated-people-looking-laptop-together_74855-4249.jpg?size=626&ext=jpg&uid=R101094498&ga=GA1.2.1966806691.1682839882&semt=ais');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
  
}

.dash-dropdown-active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}
.dash-dropdown-inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.background-find-chapter {
  width: 100%;
  height: 500px;
  background-image: url('https://img.freepik.com/free-photo/location-symbol-with-landscape-background_23-2149906278.jpg?w=996&t=st=1686235828~exp=1686236428~hmac=c906bd1c15ba99ac1a66b83f8d2caa15596ecd9e75e2aeb244f3f270449edb97');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
  box-shadow:0px 2px 4px 0px;
  border-radius: 10px;
}
.background-find-club {
  width: 100%;
  height: 500px;
  background-image: url('https://img.freepik.com/free-photo/group-ethnic-multicultural-students-sitting-table-library_496169-1216.jpg?w=996&t=st=1686236619~exp=1686237219~hmac=ced4d0fb130d27e9fb3692302d6b775e1be7108e04baafc313d20f380c96fe70');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
  box-shadow:0px 2px 4px 0px;
  border-radius: 10px;
}
.background-personal-learning {
  width: 100%;
  height: 250px;
  background-image: url('https://img.freepik.com/free-photo/medium-shot-woman-with-laptop-indoors_23-2149046235.jpg?w=900&t=st=1685975348~exp=1685975948~hmac=18a880ca457af784779b38484d5e52465162c4c457c5b98bef253099d58141d4');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-collaborative-learning {
  width: 10%;
  height: 250px;
  background-image: url('https://img.freepik.com/free-vector/people-working-open-workspace-men-woman-studying-desk-with-laptops-books-university-campus-cafe-office-workplace-business-interior-design_575670-1434.jpg?w=740&t=st=1685975427~exp=1685976027~hmac=15066159423900c9abf82f77e409c6300c11e7ccd0c977e2ac91deec49a6197d');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-academic {
  width: 10%;
  height: 250px;
  background-image: url('./assets/images/skills/academic.jpg');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-individual {
  width: 10%;
  height: 250px;
  background-image: url('./assets/images/skills/individual.jpg');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-professional {
  width: 10%;
  height: 250px;
  background-image: url('./assets/images/skills/professional.jpg');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-personal {
  width: 10%;
  height: 250px;
  background-image: url('./assets/images/skills/personal.jpg');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-leadership {
  width: 100%;
  height: 250px;
  background-image: url('./assets/images/skills/leadership.jpg');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-management {
  width: 100%;
  height: 250px;
  background-image: url('./assets/images/skills/management.avif');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-group-learning {
  width: 100%;
  height: 250px;
  background-image: url('https://img.freepik.com/free-photo/multi-ethnic-group-young-men-women-studying-indoors_1139-989.jpg?w=996&t=st=1685975383~exp=1685975983~hmac=f608c6e49487537a6ea4512740c045a4145ebc9f320c8d116235059133993d84');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.background-project-learning {
  width: 100%;
  height: 250px;
  background-image: url('https://img.freepik.com/free-photo/young-team-coworkers-working-project_273609-16279.jpg?w=996&t=st=1685975468~exp=1685976068~hmac=3aa71abc8acb0152127ef53bebf4cf7166b3e72264c3d8bfc9509d082556ad4d');
  background-size: cover;
  background-position: center;
  /* filter: brightness(0.5); */
}
.inner-shadow{
  box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.fade-in-from-top {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.App-link {
  color: #61dafb;
}
.custom-scrollbar {
  /* Set the dimensions of the container */

  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #8e8e8e;
}

.divider {
  display: flex;
  align-items: center;
}
.divider::after, .divider::before {
  flex: 1 1 0%;
  border-top: 0.0625rem solid #C4CDD5;
  content: "";
  margin-top: 0.0625rem;
}
.divider::before {
  margin-right: 1.5rem;
}
.divider::after {
  margin-left: 1.5rem;
}

.parallax-bg {
  background-image: url('./assets/profilebg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
}
.parallax-bg-learner {
  background-image: url('https://img.freepik.com/free-vector/background-with-golden-abstract-shapes_1393-355.jpg?w=1060&t=st=1688135296~exp=1688135896~hmac=def85969c4430528a5de048f6579c44390fac777ffe5b3fe3594e9c2982d26d0');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
}
.parallax-bg-chapter {
  background-image: url('./assets/chapterbg.jpg');
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
}
.parallax-bg-collab {
  background-image: url('./assets/collabbg.jpg');
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
}
.parallax-bg-club {
  background-image: url('./assets/clubbg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
  
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.red-shadow{
  box-sizing: 0px 10px 10px  rgba(255, 0, 0, 0.311);
}

.parallax-bg-club {
  background-image: url('./assets/clubbg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  
}


input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #999999;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #999999;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #999999;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #000000;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #999999;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #999999;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #000000;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #999999;
}
input[type=range]:focus::-ms-fill-upper {
  background: #999999;
}



.text-border {
  /* -webkit-text-fill-color: transparent; Set the text fill color to transparent */
  -webkit-text-strokeWidth: 1px; /* Set the border width */
  -webkit-text-stroke-color: #000000; /* Set the border color */
  color: white;
}


.reset-this {
  animation : none;
  animation-delay : 0;
  animation-direction : normal;
  animation-duration : 0;
  animation-fill-mode : none;
  animation-iteration-count : 1;
  animation-name : none;
  animation-play-state : running;
  animation-timing-function : ease;
  backface-visibility : visible;
  background : 0;
  background-attachment : scroll;
  background-clip : border-box;
  background-color : transparent;
  background-image : none;
  background-origin : padding-box;
  background-position : 0 0;
  background-position-x : 0;
  background-position-y : 0;
  background-repeat : repeat;
  background-size : auto auto;
  border : 0;
  border-style : none;
  border-width : medium;
  border-color : inherit;
  border-bottom : 0;
  border-bottom-color : inherit;
  border-bottom-left-radius : 0;
  border-bottom-right-radius : 0;
  border-bottom-style : none;
  border-bottom-width : medium;
  border-collapse : separate;
  border-image : none;
  border-left : 0;
  border-left-color : inherit;
  border-left-style : none;
  border-left-width : medium;
  border-radius : 0;
  border-right : 0;
  border-right-color : inherit;
  border-right-style : none;
  border-right-width : medium;
  border-spacing : 0;
  border-top : 0;
  border-top-color : inherit;
  border-top-left-radius : 0;
  border-top-right-radius : 0;
  border-top-style : none;
  border-top-width : medium;
  bottom : auto;
  box-shadow : none;
  box-sizing : content-box;
  caption-side : top;
  clear : none;
  clip : auto;
  color : inherit;
  columns : auto;
  column-count : auto;
  column-fill : balance;
  column-gap : normal;
  column-rule : medium none currentColor;
  column-rule-color : currentColor;
  column-rule-style : none;
  column-rule-width : none;
  column-span : 1;
  column-width : auto;
  content : normal;
  counter-increment : none;
  counter-reset : none;
  cursor : auto;
  direction : ltr;
  display : inline;
  empty-cells : show;
  float : none;
  font : normal;
  font-family : inherit;
  font-size : medium;
  font-style : normal;
  font-variant : normal;
  font-weight : normal;
  height : auto;
  hyphens : none;
  left : auto;
  letter-spacing : normal;
  line-height : normal;
  list-style : none;
  list-style-image : none;
  list-style-position : outside;
  list-style-type : disc;
  margin : 0;
  margin-bottom : 0;
  margin-left : 0;
  margin-right : 0;
  margin-top : 0;
  max-height : none;
  max-width : none;
  min-height : 0;
  min-width : 0;
  opacity : 1;
  orphans : 0;
  outline : 0;
  outline-color : invert;
  outline-style : none;
  outline-width : medium;
  overflow : visible;
  overflow-x : visible;
  overflow-y : visible;
  padding : 0;
  padding-bottom : 0;
  padding-left : 0;
  padding-right : 0;
  padding-top : 0;
  page-break-after : auto;
  page-break-before : auto;
  page-break-inside : auto;
  perspective : none;
  perspective-origin : 50% 50%;
  position : static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes : '\201C' '\201D' '\2018' '\2019';
  right : auto;
  tab-size : 8;
  table-layout : auto;
  text-align : inherit;
  text-align-last : auto;
  text-decoration : none;
  text-decoration-color : inherit;
  text-decoration-line : none;
  text-decoration-style : solid;
  text-indent : 0;
  text-shadow : none;
  text-transform : none;
  top : auto;
  transform : none;
  transform-style : flat;
  transition : none;
  transition-delay : 0s;
  transition-duration : 0s;
  transition-property : none;
  transition-timing-function : ease;
  unicode-bidi : normal;
  vertical-align : baseline;
  visibility : visible;
  white-space : normal;
  widows : 0;
  width : auto;
  word-spacing : normal;
  z-index : auto;
  /* basic modern patch */
  all: initial;
  all: unset;
}

.nav-link{
  font-size:xx-small;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dim-bg {
  backdrop-filter: brightness(0.7); /* Adjust the value as needed (0.7 means 70% brightness) */
}
/* slim scrollbar */
body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: whitesmoke;        /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
}

/* slim scrollbar */
.scrollable-element::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
.scrollable-element::-webkit-scrollbar-track {
  background: whitesmoke;        /* color of the tracking area */
}
.scrollable-element::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
}

.ql-editor {
  min-height: 12em;
}

.modal-body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
.modal-body::-webkit-scrollbar-track {
  background: whitesmoke;        /* color of the tracking area */
}
.modal-body::-webkit-scrollbar-thumb {
  background-color: gray;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid whitesmoke;  /* creates padding around scroll thumb */
}

.modal .btn-close{
  position: relative;
}

a.carousel-control-prev{
  left: 0;
  z-index: 1;
  width: 5%;
  opacity: 1;
  /* background-color: rgba(0,0,0,0.5); */
}

a.carousel-control-next{
  right: 0;
  z-index: 1;
  width: 5%;
  opacity: 1;
  /* background-color: rgba(0,0,0,0.5); */
}

.font-family{
  font-family: "Candara",Quicksand, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.7); 
}

/* custom for user dashboard */
.live-size{
  height: 400px
}

.live-image{
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(254, 230, 122);
}

.text-heading-main{
  font-size: 3vw;
}

.border-dotted{
  border-style: dotted;
}

.dropzone {
  background-color: #f2f2f2;
  border: 2px dashed #ddd;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
}

.dropzone.drag-over {
  border: 2px solid #6c757d;
}

.output {
  margin-top: 20px;
}

.error {
  color: red;
}

.profile-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}
.profile-image2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.profile-votes {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.profile-upvotes {
  font-size: 18px;
  font-weight: bold;
}

.profile-upvote-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;
  outline: none;
  margin-top: 5px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex: 1;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.profile-desc {
  font-size: 16px;
  color: #777;
}

.profile-replies {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.profile-reply-count {
  font-size: 14px;
  color: #777;
  margin-right: 10px;
}

.profile-reply-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;
  outline: none;
}

.reply-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.reply-input {
  flex: 1;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  padding: 5px;
  margin-right: 10px;
}

.reply-send-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;
  outline: none;
}

.heading-main {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 15px;
}

.heading-sub {
  font-size: 18px;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
}

.text-normal{
  font-size: 16px;
  line-height: 1.6;
}


.responsive-size{
  width: 100%;
}
@media screen and (max-width: 320px){
  .responsive-size{
    width: 40%
  }
  .live-size{
    height: 250px
  }
  .heading-main{
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 10px;
  }

  .heading-sub {
    font-size: 13px;
    font-weight: bold;
    color: #666;
    margin-bottom: 5px;
  }
  .text-normal{
    font-size: 11px;
    line-height: 1;
  }
}
@media screen and (max-width: 375px) {
  .responsive-size{
    width: 60%
  }
}

@media screen and (max-width: 768px) {
  .responsive-size{
    width: 80%
  }
  .live-size{
    height: 300px
  }
  .heading-main{
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 15px;
  }

  .heading-sub {
    font-size: 16px;
    font-weight: bold;
    color: #666;
    margin-bottom: 8px;
  }
  .text-normal{
    font-size: 14px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 425px) {
  .heading-main{
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 12px;
  }
  .heading-sub {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin-bottom: 6px;
  }
  .text-normal{
    font-size: 13px;
    line-height: 1.3;
  }
}

.shade-on-hover:hover{
  background-color: #dadada;
}

.theme-color{
  color: #0f437f;
}

.cardo {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 10px;
  height: fit-content;
  width: 23%;
}

.cardo:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.line {
  display: block;
  border-radius: 4px;
}

.assessment {
  width: 4.1rem;
  border-bottom: 5px solid #fb6c29;
}

.certificate {
  width: 2.2rem;
  border-bottom: 5px solid #33b81f;
}

.hours {
  width: 7rem;
  border-bottom: 5px solid #c827f4;
}

.score {
  width: 6.5rem;
  border-bottom: 5px solid #28c9ff;
}

.cup {
  width: 20px;
  height: 20px;
  position: absolute;
}

.containero h4 {
  margin-left: 27px;
  font-size: 15px;
}

.containero p {
  height: 50px;
  margin-bottom: 15px;
  margin-left: 5px;
  font-size: 45px;
  text-align: initial;
}

div.main {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  width: 100%;
}

/* Notification and my courses */

div.notification-course {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  width: 100%;
}


/* Notification Division */

div.notification {
  height: fit-content;
  width: 29%;
  box-shadow: 0 4px 8px 0 rgba(66, 107, 255, 0.163);
  padding: 0.5rem;
  border-radius: 8px;
}

section.notification {
  height: 447px;
  overflow-y: auto
}

div.notification p {
  font-size: 20px;
  font-weight: 500;
}

span.notification {
  border-radius: 4px;
  width: 100%;
  border-bottom: 2px solid #00678985;
}

h4.notification {
  font-size: 15px;
  margin-left: 0;
}

.notification .cardo {
  width: 95%;
  height: fit-content;
  border-left: 5px solid #000000;
}

.notification .cardo p {
  font-size: 10px;
  margin-left: 0;
  height: fit-content;
  margin-bottom: 5px;
}

.notification .cardo .containero {
  height: fit-content;
  padding-left: 0;
}

.notification .cardo .containero div {
  display: flex;
  flex-direction: row;
}

.notification .cardo .containero div img {
  width: 20px;
  height: 20px;
}

.notification .cardo .containero div span {
  font-size: 13px;
  margin-left: 3px;
}

/* My courses */
div.courses {
  height: fit-content;
  width: 69%;
  box-shadow: 0 4px 8px 0 rgba(66, 107, 255, 0.163);
  padding: 0.5rem;
  border-radius: 8px;
}

div.courses p {
  font-size: 20px;
  font-weight: 800;
}

div.courses .coursescarousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.coursecardo {
  width: 500px;
  height: fit-content;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 10px;
  height: fit-content;
}

img.course {
  height: 10rem;
  width: 100%;
}

.coursecardo .containero p {
  font-size: 20px;
  margin-bottom: 3px;
  margin: 0;
  height: fit-content;
  font-weight: 600;
}

.coursecardo .containero span {
  font-size: 17px;
  height: 20px;
  font-weight: 350;
}

.coursecardo .containero h1 {
  font-size: 15px;
  height: 20px;
  font-weight: 800;
}

.coursecardo .containero h2 {
  font-size: 13px;
  height: fit-content;
  font-weight: 100;
  width: 100%;
  line-height: 16px;
}


@media screen and (max-width: 768px) {
  div.main {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 1rem;
  }

  .cardo {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 10px;
      height: fit-content;
      width: 95%;
  }
  
  div.notification-course {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      width: 100%;
  }

  div.notification {
      height: fit-content;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(66, 107, 255, 0.163);
      padding: 0.5rem;
      border-radius: 8px;
  }

  div.courses {
      height: fit-content;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(66, 107, 255, 0.163);
      padding: 0.5rem;
      border-radius: 8px;
  }

  div.coursescarousel {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
  }

  div.coursecardo {
      width: 500px;
      display: block;
  }
}


.single-progressbar .title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

.single-progressbar {
  margin-bottom: 30px;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
}

.video-box {
  width: 400px;
  height: 300px;
  border: 1px solid #ccc;
  margin: 10px;
}

.video.active {
  border: 2px solid blue;
}

.main-speaker-video {
  width: 70%;
  height: auto;
}

.other-speakers {
  display: flex;
  flex-wrap: wrap;
}

.standard-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.grid-view {
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* This ensures that each video takes a minimum width of 300px and divides the available space equally between them. */
  gap: 1rem;  /* You can adjust the gap between videos as required */
}

.sidebar-closed {
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}

.sidebar-open {
  width: 25%;
  transition: width 0.3s ease-in-out;
}
